import { Observable } from "rxjs";
import { RespInterface, RespListInterface } from "@common/ports/interfaces";
import { CityInterface } from "../interfaces";

export abstract class CityAbstraction {
  abstract list(query: string): Observable<RespListInterface<CityInterface>>;
  abstract create(city: CityInterface): Observable<RespInterface<CityInterface>>;
  abstract update(city: CityInterface): Observable<RespInterface<string>>;
  abstract read(id: string): Observable<RespInterface<CityInterface>>;
  abstract delete(id: string): Observable<RespInterface<CityInterface>>;
}
